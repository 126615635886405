// @ts-nocheck
import React, { useState } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import { onlyNumbersValidation } from "../../utils/validations";
import { useSnackbar } from "notistack";
import api from "../../api";
import SharedForm from "./SharedForm";
import BasicDialogue from "./BasicDialogue";

export default function AddLicenseModal({
  show,
  handleClose,
  user_id,
  getLicenses,
}) {
  const [days, setDays] = useState("");
  const [camerasCount, setCamerasCount] = useState("");
  const [licenseKey, setLicenseKey] = useState("");
  const [contractId, setContractId] = useState("");
  const [successGeneration, SetSuccessGeneration] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const createLicense = () => {
    api
      .post("/public/licenses/", {
        days: days,
        cameras_count: camerasCount,
        user_id: user_id,
        contract_id: contractId,
      })
      .then(({ data }) => {
        setLicenseKey(data.id);
        getLicenses();
        handleClearForm();
        enqueueSnackbar("Success. License generated.", {
          variant: "success",
          autoHideDuration: 3000,
        });
        SetSuccessGeneration(true);
      });
  };

  const handleClearForm = () => {
    setDays("");
    setCamerasCount("");
    setContractId("");
    handleClose();
  };

  const isCreatingDisabled = (days, cameras) => {
    if (onlyNumbersValidation(days) && onlyNumbersValidation(cameras)) {
      return true;
    }
    return false;
  };

  const Content = (
    <Grid container spacing={2} direction="column">
      <Grid item xs>
        <TextField
          name="days"
          size="small"
          fullWidth
          label="Days"
          variant="outlined"
          value={days}
          onChange={(e) => setDays(e.target.value)}
          error={!onlyNumbersValidation(days) && days !== ""}
          helperText={
            !onlyNumbersValidation(days) &&
            days !== "" &&
            "The Days field only numbers greater than zero can be entered"
          }
          placeholder="Please indicate the number of days"
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="cameras_count"
          size="small"
          fullWidth
          label="Channels"
          variant="outlined"
          value={camerasCount}
          onChange={(e) => setCamerasCount(e.target.value)}
          error={!onlyNumbersValidation(camerasCount) && camerasCount !== ""}
          helperText={
            !onlyNumbersValidation(camerasCount) &&
            camerasCount !== "" &&
            "The Cameras count field only numbers greater than zero can be entered"
          }
          placeholder="Please indicate the number of channels"
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="contract_id"
          size="small"
          fullWidth
          label="Contract ID"
          variant="outlined"
          value={contractId}
          onChange={(e) => setContractId(e.target.value)}
          error={contractId.length > 100 && contractId !== ""}
          helperText={
            contractId.length > 100 &&
            contractId !== "" &&
            "The Contract ID field cannot exceed 100 characters"
          }
          placeholder="Please indicate the number of contract"
        />
      </Grid>
    </Grid>
  );

  const Action = (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleClearForm()}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={createLicense}
        disabled={
          !isCreatingDisabled(days, camerasCount) || contractId.length > 100
        }
      >
        Generate
      </Button>
    </>
  );

  return (
    <>
      <BasicDialogue
        show={show}
        handleClose={handleClose}
        title="Generate New License"
        Content={Content}
        Action={Action}
      />
      <SharedForm
        initialValue={licenseKey}
        open={successGeneration}
        onClose={() => SetSuccessGeneration((prev) => !prev)}
      />
    </>
  );
}
